export const clientNameFooter = "YOGANAYA";
export const titleMain = "Bem vindo a Yoganaya";
export const subtitleMain = "Aqui você irá imergir em autoconhecimento.";

export const aspectRatioLogoAbout = '2.5'; // width/height
export const aspectRatioLogoMain = '1'; // width/height

export const borderRadiusButtons = '10px';

export const nomeFornecedorCompleto = "YOGANAYA INTERNATIONAL SCHOOL";
export const nomeFornecedor = "YOGANAYA";
export const artigo = "a";
export const artigoCaps = "A";

export const footerSocialData = [
  {
    type: "material-community",
    name: "instagram",
    link: "https://www.instagram.com/yoganayaschool/",
    profile: "@yoganayaschool"
  },
  {
    type: "material-community",
    name: "youtube",
    link: "https://www.youtube.com/channel/UCVadlMQO740Q8nIzgnRDwZg",
  },
  {
    type: "material-community",
    name: "whatsapp",
    link: "https://api.whatsapp.com/send/?phone=%2B5511932479392&text=Ol%C3%A1%2C+gostaria+de+mais+informa%C3%A7%C3%B5es%21+&type=phone_number&app_absent=0",
    number: "(11) 93247-9392"
  },
  {
    type: "entypo",
    name: "spotify",
    link: "https://open.spotify.com/user/31ctcmjrkvkmly4ctsxw5vnwkiha?si=Ij7mRs-cRwaaEqUUQUO30A&nd=1",
  },
];

export const drawerClosedLinks = [
  {
    title: "Fale Conosco",
    link: "https://api.whatsapp.com/send/?phone=%2B5511932479392&text=Ol%C3%A1%2C+gostaria+de+mais+informa%C3%A7%C3%B5es%21+&type=phone_number&app_absent=0",
  },
  {
    title: "Blog",
    link: "https://yoganaya.com.br/blog/o-poder-dos-mantras-dos-arquetipos-do-sagrado-feminino/",
  },
  {
    title: "REyoga",
    link: "https://app.reyoga.com.br/",
  },
];

export const linkAppleStore = "https://apps.apple.com/us/app/yoganaya-international-school/id1644551416"
export const linkPlayStore = "https://play.google.com/store/apps/details?id=com.yoganayaschool.mobileblixstream"

export const hasOutsideAboutPage = false;
export const drawerOpenedLinks = [];